<template>
    <nav>
        <v-app-bar flat app>
            <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-uppercase grey--text">
                <span class="font-weight-light">Карты.</span>
                <span>Крым</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!--v-btn icon>
                <v-icon>mdi-export</v-icon>
            </v-btn-->
        </v-app-bar>

        <v-navigation-drawer app-bar app v-model="drawer">
            <v-list>
                <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
                    <v-list-item-action>
                        <v-icon class="grey--text">{{ link.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="grey--text">
                        {{link.text}}
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>

    </nav>
</template>

<script>

export default {
    data() {
        return {
            drawer: false,
            links: [
                {icon: 'mdi-view-dashboard', text: 'Карта', route: '/'},
                {icon: 'mdi-access-point-check', text: 'О проекте', route: '/about'}
            ]
        }
    }
}

</script>
