<template>
  <div class="dashboard">
    <v-container class="my-5">
      <Map></Map>
    </v-container>
  </div>
</template>

<script>
import Map from '@/components/MapOl.vue'
 
export default {
  components: {
   Map
  }
}
</script>
<style>

#map {
   width: 100%;
   height: 760px;
   margin: 0;
   padding: 0;
}
</style>
