import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueHead from 'vue-head'
import VueMeta from 'vue-meta'

Vue.use(Vuetify);
Vue.use(VueHead)
Vue.use(VueMeta)

export default new Vuetify({
    icons: {
        iconfont: 'mdi', 
      }
});
