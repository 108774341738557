<template>
  <v-app class="grey lighten-4">
    <Navbar />
    <v-main class="mx-4 mb-4">
      <router-view></router-view>
    </v-main>
    <v-footer padless>
    <v-col class="text-center grey--text" cols="12">
      {{ new Date().getFullYear() }} — <strong>CrimeaMaps.RU</strong>
    </v-col>
  </v-footer>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'App',
  components: { Navbar },
  metaInfo: {
    title: 'CrimeaMaps.RU',
    titleTemplate: '%s | Карты Крыма'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'description', content: 'Карты Крыма.' },
    { name: 'keywords', content: 'Карты,Крым,туризм,экскурсии,отдых,путеводитель'}
  ],

  data: () => ({
    //
  }),
};
</script>
