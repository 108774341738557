<template>
    <div id = "map">
    </div>
</template>
 
<script>
import "ol/ol.css";
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
//import { OSM } from 'ol/source';
import XYZ from 'ol/source/XYZ';
//import {fromLonLat} from 'ol/proj';
 
export default {
    data() {
        return {
            //center: fromLonLat([45.22, 34.51], 'EPSG:3857')
            center: [3807175.504828, 5650225.130840]
    }},
    name: 'Map',
    mounted() {
        this.map = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    //source: new OSM()
                    source: new XYZ({
                        url: 'https://crimeamaps.ru/hot/{z}/{x}/{y}.png'
                    })
                })
            ],
            view: new View({
                center: this.center,
                zoom: 9
            })
        })
    }
}
</script>